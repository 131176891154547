import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/mitgliederversammlung/2015/01.jpg"
import Image02 from "../../../images/mitgliederversammlung/2015/02.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Mitgliederversammlung2015 = props => {
  return (
    <Layout>
      <SEO title="Mitgliederversammlung 2015" lang="de" />
      <Container>
        <h1 className="h3">
          Mitgliederversammlung des Schützenverein 1961 Gambach e.V.
        </h1>
        <hr className="featurette-divider" />
        <small>Aus der Butzbacher Zeitung</small>
        <h6>Rudi Haas seit 25 Jahren 2. Vorsitzender</h6>
        <p>
          (fs) Zur Ordentlichen Mitgliederversammlung des Schützenverein 1961
          Gambach e.V. im Gambacher Schützenhaus konnte der 1. Vorsitzende
          Friedhelm Schwenz zahlreiche Mitglieder begrüßen und über ein
          erfolgreiches Jahr 2014 berichten.
        </p>
        <p>
          Nach dem Gedenken an die verstorbenen Mitglieder Marga Mayer und
          Wolfgang Köstler berichtete der Vorsitzende über die Arbeit des
          Vorstandes im abgelaufenen Jahr. In vier Vorstandssitzungen wurden
          wesentliche Themen des Vereinslebens, der allgemeine Schießbetrieb,
          die verschiedenen Meisterschaften, sowie die gesellschaftlichen
          Veranstaltungen des Vereins behandelt. Weiterhin ließ er die
          verschiedenen gesellschaftlichen und kulturellen Veranstaltungen des
          vergangenen Jahres noch einmal Revue passieren. Dazu zählten u.a. ein
          gut besuchtes Schlachtessen im Februar, die Mitwirkung am
          »Oster-Fez-Ferienspaß« der Stadt Münzenberg, sowie im März die
          Beteiligung an der Aktion »Sauberhaftes Münzenberg«. Ein intensives
          Thema waren natürlich die Vorbereitung und die Durchführung des 35.
          Wanderpokalschießens. Das Sommerfest mit dem 19. Königsschießen war
          ebenfalls wieder eine sehr gelungene Veranstaltung. Natürlich war auch
          die Beteiligung an der Kirmes 2014 ein weiterer Höhepunkt. Gegen Ende
          des Jahres fand wie immer das traditionelle Nikolausschießen statt,
          eine größere Abordnung nahm am Würfelturnier in Münzenberg teil.
          Besucht wurden weiterhin auch verschiedene Jubiläumsveranstaltungen
          von Vereinen der Stadt. Schwenz bedankte sich noch einmal bei allen
          Teilnehmern und den Helfern der verschiedenen Veranstaltungen sowie
          den Spendern. Entsprechend der Ehrenordnung des Vereins wurden
          insgesamt 8 Geburtstags-Jubilare besucht und mit einem kleinen Präsent
          beglückwünscht. Die Mitgliederstatistik war zum Jahresende konstant
          geblieben. Der Vorsitzende begrüßte zwei neue jugendliche Mitglieder
          im Kreise der Schützenfamilie.
        </p>
        <p>
          Nachfolgend wurde Rudi Haas für langjährige Vorstandstätigkeit als
          stellvertretender Vorsitzender geehrt. Er bekleidet das Amt seit
          nunmehr 25 Jahren und wurde dafür mit einer Urkunde und einer
          Ehrennadel des Deutschen Schützenbundes ausgezeichnet. In einer
          weiteren Ehrung wurde anschließend der Vorsitzende Schwenz für seine
          40jährige Vereinszugehörigkeit ausgezeichnet. Der anwesende
          Bürgermeister Hans Jürgen Zeiß sprach in einer kurzen Ansprache den
          Dank an Schwenz für seine Tätigkeit im Verein aus. Rudi Haas
          überreichte die Urkunde und die Ehrennadel.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Das Bild zeigt den 1. Vorsitzenden Friedhelm Schwenz (40jährige
              Mitgliedschaft) und den 2. Vorsitzenden Rudolf Haas der für seine
              25-jährigeVorstandstätigkeit geehrt wurde. (Foto: wba)
            </small>
          </Col>
        </Row>
        <p></p>
        <p>
          Im anschließenden Sportbericht ging Schießwart Harald Fink detailliert
          auf die sportlichen Höhepunkte des vergangenen Jahres ein. Zu Beginn
          des Sportjahres standen wie immer die Vereinsmeisterschaften mit den
          Luftdruckwaffen, sowie in den KK-Disziplinen. Es folgten die Kreis-,
          Gau- und Hessenmeisterschaften, wo gute vordere Plätze belegt wurden.
          Uwe Wagner hatte sich in der Disziplin GK 300m für die Deutschen
          Meisterschaften qualifiziert und dort einen Platz im Mittelfeld
          belegt. Alle angetretenen Mannschaften konnten sich einen
          Klassenerhalt sichern und belegten gute Abschlussplätze. Nachfolgend
          ging Jugendwart Michael Rheinfrank detailliert auf die Jugendarbeit
          ein. Dass sich ein ausgiebiges Training lohnt, wurde bei den
          Meisterschaften bestätigt. Hier wurde Hendrik Ruf in der Jugendklasse
          Kreismeister und Lars Wächter in der Juniorenklasse B Vizemeister.
          Jonas Muschalik erreichte in der Schülerklasse den 3. Platz. Ebenfalls
          in der Schülerinnenklasse gestartet war Fabienne Hofmann. Alle vier
          Starter hatten sich damit für die Gaumeisterschaften qualifiziert und
          hier belegte Henrik Ruf in der Jugendklasse den Vizemeistertitel. Bei
          den im Juni stattgefundenen Hessenmeisterschaften nahmen Hendrik Ruf
          und Jonas Muschalik teil. Beim Jahrgangs-Besten-Schießen des
          Schützenkreises Friedberg belegten alle Gambacher Jugendschützen
          vordere Plätze.
        </p>
        <p>
          Schwenz bedankte sich herzlich für das Engagement des Jugendleiters
          Michael Rheinfrank und bei den aktiven Schützen, die das
          Trainingsschießen unterstützen.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image02} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Die erfolgreichen Nachwuchsschützen mit ihrem Jugendtrainer
              Michael Rheinfrank (v.l.: Michael Rheinfrank, Lars Wächter,
              Fabienne Hofmann, Fabian Zeh und Hendrik Ruf, es fehlt: Jonas
              Muschalik (Foto: kba)
            </small>
          </Col>
        </Row>
        <p></p>
        <p>
          Der anschließende Bericht von Kassenwart Lothar Döring zeigte
          geordnete und wirtschaftliche Verhältnisse des Vereins auf. Im Namen
          der Kassenprüfer attestierte Anne-Dore Becker eine vorbildliche
          Führung der Kassen- und Buchführungsgeschäfte und beantragte die
          Entlastung des Gesamtvorstandes, welchem die Versammlung in der
          anschließenden Abstimmung einstimmig folgte.
        </p>
        <p>
          Unter dem Tagesordnungspunkt Satzungsänderung, wurden verschiedene
          Punkte der bestehenden Satzung geändert und einen weiteren Punkt
          ergänzt. Unter Punkt Verschiedenes wurden Themen bezüglich des
          laufenden Vereinsjahres behandelt.
        </p>
        <p>
          Anschließend gab Schwenz noch die weiteren Termine für das laufende
          Jahr bekannt. Hierzu zählen neben dem 36. Wanderpokalschießen für
          Vereine der Stadt Münzenberg im April, das Sommerfest am 8. August.
          Das Jahr endet mit dem traditionellen Nikolausschießen im Dezember.
          Die Mitgliederversammlung klang gut eineinhalb Stunden mit einem
          gemütlichen Beisammensein aus.
        </p>
      </Container>
    </Layout>
  )
}

export default Mitgliederversammlung2015
